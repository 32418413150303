import top from '@/components/top.vue';
import sideBarItem from "@/Layout/sideBar/sideBarItem";
import TagsView from "./TagsView";
import sideBar from '@/components/sideBar.vue';
import Api from "@/utils/api";
export default {
  components: {
    top,
    sideBarItem,
    TagsView,
    sideBar
  },
  computed: {
    menuList() {
      return this.$router.options.routes;
    },
    defaultActive() {
      const route = this.$route;
      const {
        path
      } = route;
      return path;
    }
  },
  data() {
    return {
      time: null,
      timer: null
    };
  },
  destroyed() {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  },
  mounted() {
    this.getTime();
    this.timer = window.setInterval(() => {
      this.getTime();
    }, 1000);
  },
  methods: {
    getTime() {
      this.time = this.$moment().format("YYYY年MM月DD日 HH:mm:ss");
    }
  }
};