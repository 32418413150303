export default {
  data() {
    return {
      only: true,
      isHome: false
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes.filter(i => i.children);
    },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      if (route.name == '工作台') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
      return path;
    }
  }
};