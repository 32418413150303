import "core-js/modules/es.array.push.js";
import Api from "@/utils/api";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  data() {
    return {
      user: {
        name: '商超A',
        id: '030451'
      },
      form: {},
      prisonList: [],
      prison: {}
    };
  },
  mounted() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    // this.getList()
  },
  methods: {
    getVale(v) {
      this.prison = v;
      window.localStorage.setItem('prison', JSON.stringify(v));
    },
    async getList() {
      try {
        const res = await Api.getUserPrisonList();
        this.prisonList = res;
        this.prison = res[0];
        window.localStorage.setItem('prison', JSON.stringify(this.prison));
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    async save() {
      try {
        const res = await Api.addPrisonByInvCode(this.form);
        ElMessage.success('新增供货监所成功!');
        this.cancel();
      } catch (error) {
        ElMessage.error(error.toString());
      }
    },
    async out() {
      try {
        const res = await Api.out();
        window.localStorage.clear();
        this.$router.push("/login");
      } catch (error) {
        ElMessage.error(error.toString());
      }
    }
  }
};