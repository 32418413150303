import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-45730a1a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  slot: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_item = _resolveComponent("item");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_sidebar_item = _resolveComponent("sidebar-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return !$props.item.hidden ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$options.hasOneShowingChild($props.item.children, $props.item) && (!_ctx.onlyOneChild.children || _ctx.onlyOneChild.noShowingChildren) && !$props.item.alwaysShow && _ctx.onlyOneChild.meta ? (_openBlock(), _createBlock(_component_el_menu_item, {
    key: 0,
    index: _ctx.onlyOneChild.name,
    class: _normalizeClass([{
      'submenu-title-noDropdown': !$props.isNest
    }, "itwm"])
  }, {
    default: _withCtx(() => [_createVNode(_component_item, {
      icon: _ctx.onlyOneChild.meta.icon || $props.item.meta && $props.item.meta.icon,
      title: _ctx.onlyOneChild.meta.title
    }, null, 8, ["icon", "title"])]),
    _: 1
  }, 8, ["index", "class"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 1,
    ref: "subMenu",
    index: $props.item.path,
    "popper-append-to-body": ""
  }, {
    default: _withCtx(() => [_createElementVNode("template", _hoisted_2, [$props.item.meta ? (_openBlock(), _createBlock(_component_item, {
      key: 0,
      icon: $props.item.meta && $props.item.meta.icon,
      title: $props.item.meta.title
    }, null, 8, ["icon", "title"])) : _createCommentVNode("", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, child => {
      return _openBlock(), _createBlock(_component_sidebar_item, {
        key: child.path,
        "is-nest": true,
        item: child,
        "base-path": child.path,
        class: "nest-menu"
      }, null, 8, ["item", "base-path"]);
    }), 128))]),
    _: 1
  }, 8, ["index"]))])) : _createCommentVNode("", true);
}