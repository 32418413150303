import { http } from './http'
class Api {

  //登录
  static async login(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/login?_allow_anonymous=true`,
        data: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //注册
  static async register(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/register`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //登录
  static async out() {
    try {
      const { data } = await http({
        method: 'post',
        url: `/logout`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取登录验证码
  static async getCode() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/validate/getCode`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取登录用户信息
  static async getUser() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/user/current`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取监所下拉
  static async getPrisonList(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/prison/orgnizationTree`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //短信发送验证码
  static async sendMessage(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/message`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //企业资质认证状态
  static async authStatus(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/authStatus`,
        params: val
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //注册
  static async submitAuth(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/submitAuth`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改密码
  static async forgetPwd(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/store/forgetPwd`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取当前商家
  static async getcurrent(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/current`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  static async getCurrentWithAddress(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/getCurrentWithAddress`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改商超信息
  static async changeStore(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/store`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商品分类
  static async goodCategory(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/goodCategory`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商品表格
  static async getGoodsList(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/management/storeGood/getListPage`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商品上下架
  static async toOnline(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/storeGood/toOnline`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传商品图片
  static async uploadGoodsImg(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/upload/goods`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传商品
  static async storeGood(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/storeGood`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //新增供货监所
  static async addPrisonByInvCode(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/addPrisonByInvCode`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //新增供货监所
  static async addPrisonByInvCode(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/store/addPrisonByInvCode`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单表格
  static async getNoDeliveryOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getNoDeliveryOrder`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //unlock
  static async unlockInfo(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/unlock`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取商户监所列表
  static async getUserPrisonList() {
    try {
      const { data } = await http({
        method: 'get',
        url: `/store/getUserPrisonList`,
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //是否有订单商户未付钱
  static async checkLocking(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/checkLocking`,
        data: val
      })
      if (data.code === 0) {
        return data
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传付款凭证
  static async uploadReceipt(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/uploadReceipt`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //退单回执
  static async commitRefundReceipt(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/commitRefundReceipt`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传负票
  static async commitNegativeTicket(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/commitNegativeTicket`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  
  //订单详情
  static async getNoDeliveryOrderDetail(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getNoDeliveryOrderDetail`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //导出商品
  static async exportStoreGood(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/storeGood/exportStoreGood`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超发货
  static async deliveryByPrisonRoom(val) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/shoppingNew/deliveryByPrisonRoom`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超子订单表格
  static async getChildDeliveryOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getChildDeliveryOrder`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
    //获取总订单号的下拉
    static async getDeliverNo(val) {
      try {
        const { data } = await http({
          method: 'get',
          url: `/b-store-pay/getDeliverNo`,
          params: val
        })
        if (data.code === 0) {
          return data.response
        }
      } catch (error) {
        throw new Error(error)
      }
    }
    //子订单号下拉
  static async getChildSn(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getChildSn`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //监所下拉
  static async getPrisonListAll(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/prison/all`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //交易记录
  static async getStorePayPage(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/page`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超入驻信息列表
  static async getStoreListPage(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/management/store/getListPage`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //设置冻结状态账户
  static async modifyFrozenStatusAccount(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/management/store/modifyFrozenStatus`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //设置冻结状态看守所
  static async modifyFrozenStatusPrison(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/management/storeAddress/modifyFrozenStatus`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //设置资质审核状态-平台审核
  static async modifyAuthStatusPlatform(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/management/store/modifyAuthStatusPlatform`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //修改商家供货地址-费率
  static async modifyStoreRate(id) {
    try {
      const { data } = await http({
        method: 'put',
        url: `/management/storeAddress/modifyRate`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取单个商家
  static async getStoreById(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/management/store/${id}`,
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //公告新增
  static async bulletinAdd(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-bulletin/add`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //公告删除
  static async bulletinDel(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-bulletin/delete`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //公告更新
  static async bulletinUpdate(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-bulletin/update`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //公告列表
  static async getBulletinList(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-bulletin/list`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //获取所有商家(下拉列表)
  static async getStoreList(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/management/store/all`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单表格
  static async getNoDeliveryOrder(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/shoppingNew/getNoDeliveryOrder`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查看交易回执附件
  static async getOrderReceipt(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getReceipt`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查看回执附件
  static async getOrderFiles(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getOrderFiles`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  //回执审核通过
  static async examineOK(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/examine`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //回执审核失败
  static async refuseNO(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/refuse`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  static async examineOK(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/examine`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查询交易记录详情
  static async getOrderdetail(id) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/detail`,
        params: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //生成授权码-平台
  static async addCodePlatform(id) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/web/invitationCode/addCodePlatform`,
        data: id
      })
      if (data.status === 200) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //查看交易记录流程
  static async getOrderFlow(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/b-store-pay/getOrderFlow`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //总计收入
  static async adminIncome(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/adminIncome`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //订单解锁量
  static async storeConfirm(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/storeConfirm`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //商超入驻
  static async storeJoin(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/storeJoin`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //累计退款
  static async storeRefund(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/workbench/storeRefund`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //退单通过
  static async orderRefundPass(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/orderRefundPass`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //退单拒绝
  static async orderRefundReject(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/orderRefundReject`,
        params: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //上传发票
  static async commitTicket(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/b-store-pay/commitTicket`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
   //系统文档添加
   static async docAdd(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/s-doc/add`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //系统文档添加
  static async docUpdate(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/s-doc/update`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //系统文档删除
  static async docDel(val) {
    try {
      const { data } = await http({
        method: 'post',
        url: `/s-doc/delete`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  //系统文档列表
  static async getDocList(val) {
    try {
      const { data } = await http({
        method: 'get',
        url: `/s-doc/list`,
        data: val
      })
      if (data.code === 0) {
        return data.response
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  

}

export default Api