// 初始化一个vuex的实例（数据仓库） 导出即可
// import Vuex from 'vuex'
// import Vue from 'vue'
import { createStore } from "vuex";
import tagsView from './tagsView'

// 使用安装
// Vue.use(Vuex)

// 初始化
// const store = new Vuex.Store({
//   // 配置（state|mutations|actions）
//   modules: {
//     tagsView: tagsView
//   }
// })
const store = createStore({
  modules: {
       tagsView: tagsView
  },
});
export default store
