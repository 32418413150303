import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
export default defineComponent({
  components: {
    ElConfigProvider
  },
  name: 'App',
  setup() {
    return {
      locale: zhCn
    };
  }
});