import { createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  },
  render(h, context) {
    const vnodes = [];
    if (this.icon) {
      vnodes.push(_createVNode("i", {
        "class": [this.icon, "iconfont"]
      }, null));
    }
    if (this.title) {
      vnodes.push(_createVNode("span", {
        "slot": "title",
        "class": "title"
      }, [this.title]));
    }
    return vnodes;
  }
};