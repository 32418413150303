import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-458c8dc6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Layout"
};
const _hoisted_2 = {
  class: "layoutContent"
};
const _hoisted_3 = {
  class: "left-nav"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "router-tab"
};
const _hoisted_6 = {
  class: "p"
};
const _hoisted_7 = {
  class: "routerBox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top = _resolveComponent("top");
  const _component_sideBar = _resolveComponent("sideBar");
  const _component_TagsView = _resolveComponent("TagsView");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top, {
    isSOU: true
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_sideBar)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_TagsView, {
    class: "tagView"
  }), _createElementVNode("p", _hoisted_6, _toDisplayString($data.time), 1)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_router_view)])])])]);
}